import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from '../services/data/data.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { RecMapComponent } from '../rec-map/rec-map.component';
import { ApiService } from '../services/api/api.service';
import { startWith, map } from 'rxjs/operators';


@Component({
  selector: 'app-package-recipient',
  templateUrl: './package-recipient.component.html',
  styleUrls: ['./package-recipient.component.scss']

})
export class PackageRecipientComponent implements OnInit {
  @Input() globalForm;

  point;
  allPoints;
  newPoints: string[] = [];
  filteredOptions;
  zipMask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/];
  phoneMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

  public subscription: Subscription;
  public shipmentForm: FormGroup;

  constructor(private dialog: MatDialog, private api: ApiService, ) {

  }

  public ngOnInit() {
    this.api.getPoints().subscribe(res => {
      this.allPoints = res;
      for (let i = 0; i < this.allPoints.length; i++) {
        this.newPoints.push(this.allPoints[i].Point.No + ', ' + this.allPoints[i].Address.Street + ', ' + this.allPoints[i].Address.ZipCode + " " + this.allPoints[i].Address.City)
      }
    });
    this.filterFn();
  }
  filterFn() {
    if(this.globalForm.get('recipient.recipientDeliveryChecbox').value === false) {  
      this.filteredOptions = this.globalForm.get('recipient.point.visibleNo').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    } 
  }
  private _filter(value): string[] {
    const filterValue = value.toLowerCase();

    return this.newPoints.filter(option => option.toLowerCase().includes(filterValue));
  }

  inputControl(event) {
    setTimeout(() => {
      let isValueTrue = this.newPoints.filter(myAlias =>
        myAlias === event.target.value
      );

      if (isValueTrue.length === null) {
        this.globalForm.get('recipient.point.visibleNo').setValue("");
      }
      if (isValueTrue.length !== 0 && this.globalForm.get('recipient.recipientDeliveryChecbox').value === false) {
        let newPoint = event.target.value.split(",", 1).toString();
        this.globalForm.get('recipient.point.no').patchValue(newPoint);       
      }
    }, 300);
  }

  public copyShipmentForm(): void {
    this.globalForm.get('recipient.address').patchValue(this.globalForm.get('payer.address').value);
    this.globalForm.get('recipient.name').patchValue(this.globalForm.get('payer.name').value);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RecMapComponent, {
      width: '60%'
    });


    dialogRef.afterClosed().subscribe(result => {
      this.point = result;
      if (this.point !== undefined) {
        const finalPoint = this.point.id + ", " + this.point.a;
        this.globalForm.get('recipient.point.visibleNo').patchValue(finalPoint);
        this.globalForm.get('recipient.point.no').patchValue(this.point.id);
      }
    });
  }


}
