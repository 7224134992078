import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

letter;

  constructor(private router: Router) { }

  ngOnInit() {
  }
redirect(letterNo) { 
  this.router.navigate(['/tracking', {letterNo: letterNo.value}]);
  this.letter = "";
}
}
