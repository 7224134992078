import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unsucces',
  templateUrl: './unsucces.component.html',
  styleUrls: ['./unsucces.component.scss']
})
export class UnsuccesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
