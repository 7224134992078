import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api/api.service';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss']
})
export class PendingComponent implements OnInit {

  private guid;
  private status;
  private qr;  

  constructor(private route: ActivatedRoute, private router: Router, private api: ApiService) { }


  ngOnInit() {
    const time = setInterval(testFunction.bind(this), 2000);

    function testFunction() {
    this.route.queryParamMap.subscribe(params => {
      this.guid = params.get("t");
      this.api.getStatus(this.guid).subscribe(res => {
        this.status = res;        
        if (this.status.PaymentStatus === "Success") {
          clearInterval(time);
          this.api.getShipment(this.guid).subscribe(res => {
            this.qr = res;          
            if (this.qr.Shipment.QrCodeInfo == 'SendByQrCode') {
              this.router.navigate(['/confirmation-qr'], { queryParams: {t: this.guid}});
            } else {
              this.router.navigate(['/confirmation-print'], { queryParams: {t: this.guid}});
            }
          })
        } 
        else if (this.status.PaymentStatus === "Error") {
          clearInterval(time);
          this.router.navigate(['/unsucces']);
        }        
      }, err => {        
        if(err.status == '500') {
          clearInterval(time);
          this.router.navigate(['/unsucces']);
        }
      })
    }
    )
 
    }


  }
}
