import { FormGroup, FormControl, Validators } from '@angular/forms';

export class Form {
  shipment: FormGroup;

  constructor() {
    this.shipment = new FormGroup({
      parcel: new FormGroup({
        weight: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
        dimensions: new FormGroup({
          length: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
          height: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
          width: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')])
        })
      }),
      cod: new FormGroup({
        checkbox: new FormControl(false),
        amount: new FormControl(''),
        accountNumber: new FormControl('')
      }),
      insurance: new FormGroup({
        checkbox: new FormControl(false),
        amount: new FormControl('')
      }),
      payer: new FormGroup({
        invoice: new FormControl(false),
        privatePerson: new FormControl(true),
        taxId: new FormControl(''),
        name: new FormControl(''),
        address: new FormGroup({
          street: new FormControl(''),
          houseNo: new FormControl(''),
          flatNo: new FormControl(''),
          zipCode: new FormControl(''),
          city: new FormControl('')
        })
      }),
      
      sender: new FormGroup({
        senderDeliveryChecbox: new FormControl(undefined),
        contact: new FormGroup({
          emailAddress: new FormControl(''),
          phoneNumber: new FormControl('')
        }),
        name: new FormControl(''),
        address: new FormGroup({
          street: new FormControl(''),
          houseNo: new FormControl(''),
          flatNo: new FormControl(''),
          zipCode: new FormControl(''),
          city: new FormControl(''),
          deliveryPoint: new FormControl('')
        })
      }),
      recipient: new FormGroup({
        recipientDeliveryChecbox: new FormControl(undefined),
        contact: new FormGroup({
          emailAddress: new FormControl(''),
          phoneNumber: new FormControl('')
        }),
        name: new FormControl(''),
        address: new FormGroup({
          street: new FormControl(''),
          houseNo: new FormControl(''),
          flatNo: new FormControl(''),
          zipCode: new FormControl(''),
          city: new FormControl(''),
          deliveryPoint: new FormControl('')
        })
      }),
      payment: new FormGroup({
        payNow: new FormControl(true),
        print: new FormControl(true),
        qr: new FormControl(false)
      })
    });


  }
}