import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ApiService } from "../services/api/api.service";
import { DataService } from "../services/data/data.service";

@Component({
  selector: "app-package-additional",
  templateUrl: "./package-additional.component.html",
  styleUrls: ["./package-additional.component.scss"]
})
export class PackageAdditionalComponent implements OnInit {
  @Input() globalForm;

  private insurance;

  constructor(private api: ApiService, private data: DataService) {}

  public ngOnInit() {
    this.globalForm.get("cod.checkbox").valueChanges.subscribe(checked => {
      if (checked) {
        const validators = Validators.compose([
          Validators.required,
          Validators.minLength(26),
          Validators.pattern("^[0-9]*$")
        ]);
        this.globalForm
          .get("cod")
          .addControl(
            "amount",
            new FormControl(
              "",
              Validators.compose([
                Validators.required,
                Validators.pattern("^[0-9]*$")
              ])
            )
          );
        this.globalForm
          .get("cod")
          .addControl("accountNumber", new FormControl("", validators));
      } else {
        this.globalForm.get("cod").removeControl("amount");
        this.globalForm.get("cod").removeControl("accountNumber");
      }
      this.globalForm.updateValueAndValidity();
    });
  }

  checkInsurance(checked: boolean) {
    if (checked) {
      this.api.getInsurance().subscribe(res => {        
        this.insurance = res;
        this.globalForm.get('insurance.amount').patchValue(7000);
        this.data.packageInsurance$.next(this.insurance.Price.Amount);
      });
    } else {
      this.data.packageInsurance$.next(undefined);
      this.globalForm.get('insurance.amount').patchValue(null);
    }
  }
}
