import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';



import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { PackageInfoComponent } from './package-info/package-info.component';
import { PackageSummaryComponent } from './package-summary/package-summary.component';
import { PackageSizeComponent } from './package-size/package-size.component';
import { PackageAdditionalComponent } from './package-additional/package-additional.component';
import { PackageInvoiceComponent } from './package-invoice/package-invoice.component';
import { ApiService } from './services/api/api.service';
import { DataService } from './services/data/data.service';
import { PackageDeliveryComponent } from './package-delivery/package-delivery.component';
import { PackageSenderComponent } from './package-sender/package-sender.component';
import { PackageRecipientComponent } from './package-recipient/package-recipient.component';
import { PackagePaymentComponent } from './package-payment/package-payment.component';
import { SaveComponent } from './save/save.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SummaryQrComponent } from './summary-qr/summary-qr.component';
import { SummaryPrintComponent } from './summary-print/summary-print.component';
import { SearchPackageComponent } from './search-package/search-package.component';
import { TrackingComponent } from './tracking/tracking.component';
import { AppRoutingModule } from './app-routing.module';
import { FormDirective } from './focus-invalid-input.directive';
import { MatSliderModule } from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import { SendMapComponent } from './send-map/send-map.component';
import { RecMapComponent } from './rec-map/rec-map.component';
import { PendingComponent } from './pending/pending.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { UnsuccesComponent } from './unsucces/unsucces.component';
import { MatAutocompleteModule, MatTableModule } from '@angular/material';
import { TextMaskModule } from 'angular2-text-mask';
import { PackageDialogComponent } from './package-dialog/package-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PackageInfoComponent,
    PackageSummaryComponent,
    PackageSizeComponent,
    PackageAdditionalComponent,
    PackageInvoiceComponent,
    PackageDeliveryComponent,
    PackageSenderComponent,
    PackageRecipientComponent,
    PackagePaymentComponent,
    SaveComponent,
    FooterComponent,
    HomeComponent,
    SummaryQrComponent,
    SummaryPrintComponent,
    SearchPackageComponent,
    TrackingComponent,
    FormDirective,
    SendMapComponent,
    RecMapComponent,
    PendingComponent,
    UnsuccesComponent,
    PackageDialogComponent
  ],
  imports: [
    BrowserModule,    
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatSliderModule,
    MatDialogModule,
    MatAutocompleteModule,
    TextMaskModule,
    MatTableModule
  ],
  providers: [ApiService, DataService, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  entryComponents: [SendMapComponent, RecMapComponent, PackageDialogComponent]
})
export class AppModule { }
