import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-package',
  templateUrl: './search-package.component.html',
  styleUrls: ['./search-package.component.scss']
})
export class SearchPackageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
