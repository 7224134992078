import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs/internal/Subscription";
import { MatDialog } from "@angular/material";
import { ApiService } from "../services/api/api.service";
import { SendMapComponent } from '../send-map/send-map.component';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';


@Component({
  selector: "app-package-sender",
  templateUrl: "./package-sender.component.html",
  styleUrls: ["./package-sender.component.scss"]
})
export class PackageSenderComponent implements OnInit, OnDestroy {
  @Input() globalForm;

  public subscription: Subscription;
  public shipmentForm: FormGroup;
  point;
  allPoints;
  newPoints: string[] = [];
  filteredOptions;
  zipMask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/];
  phoneMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  // codes;


  constructor(private api: ApiService, private dialog: MatDialog) {
    this.subscription = new Subscription();

  }

  public ngOnInit() {

    this.api.getPoints().subscribe(res => {
      this.allPoints = res;
      for (let i = 0; i < this.allPoints.length; i++) {
        this.newPoints.push(this.allPoints[i].Point.No + ', ' + this.allPoints[i].Address.Street + ', ' + this.allPoints[i].Address.ZipCode + " " + this.allPoints[i].Address.City)
      }
    });
    this.filterFn();
  }
  filterFn() {
    if (this.globalForm.get('sender.senderDeliveryChecbox').value === false) {
      this.filteredOptions = this.globalForm.get('sender.point.visibleNo').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    }
  }

  private _filter(value): string[] {
    const filterValue = value.toLowerCase();

    return this.newPoints.filter(option => option.toLowerCase().includes(filterValue));
  }

  inputControl(event) {
    setTimeout(() => {
      let isValueTrue = this.newPoints.filter(myAlias =>
        myAlias === event.target.value
      );

      if (isValueTrue.length === null) {
        this.globalForm.get('sender.point.visibleNo').setValue("");
      }
      if (isValueTrue.length !== 0 && this.globalForm.get('sender.senderDeliveryChecbox').value === false) {
        let newPoint = event.target.value.split(",", 1).toString();
        this.globalForm.get('sender.point.no').patchValue(newPoint);
      }
    }, 300);
  }

  public copyShipmentForm(): void {
    this.globalForm
      .get('sender.address')
      .patchValue(this.globalForm.get('payer.address').value);
    this.globalForm
      .get("sender.name")
      .patchValue(this.globalForm.get('payer.name').value);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SendMapComponent, {
      width: '60%'
    });


    dialogRef.afterClosed().subscribe(result => {
      this.point = result;
      if (this.point !== undefined) {
        const finalPoint = this.point.id + ", " + this.point.a;
        this.globalForm.get('sender.point.visibleNo').patchValue(finalPoint);
        this.globalForm.get('sender.point.no').patchValue(this.point.id);
      }
    });
  }


  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
