import { Directive, ElementRef, HostListener, Input, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class FormDirective implements AfterContentInit{

  @Input() public autoFocus: boolean;

  constructor(private el: ElementRef) { }

  ngAfterContentInit( ){
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 500)
  }

  @HostListener ('submit')
  onFormSubmit() {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');

    if (invalidControl) {
      invalidControl.focus();
    }
  }

}
