import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ApiService } from "../services/api/api.service";
import { DataService } from "../services/data/data.service";
import { Subscription } from "rxjs/internal/Subscription";
import { MatDialog } from '@angular/material';
import { PackageDialogComponent } from '../package-dialog/package-dialog.component';


@Component({
  selector: "app-package-size",
  templateUrl: "./package-size.component.html",
  styleUrls: ["./package-size.component.scss"]
})
export class PackageSizeComponent implements OnInit, OnDestroy {
  @Input() globalForm;

  summary;

  constructor(private api: ApiService, private data: DataService, public dialog: MatDialog) {}

  ngOnInit() {}
  public getPrice(): any {
    if (
      this.globalForm.get("parcel.weight").valid &&
      this.globalForm.get("parcel.dimensions.lenght").valid &&
      this.globalForm.get("parcel.dimensions.height").valid &&
      this.globalForm.get("parcel.dimensions.width").valid
    ) {
      this.api
        .getPackagePrice(this.globalForm.controls.parcel.value)
        .subscribe(res => {
          this.summary = res;
          if (this.summary) {
            this.data.packageSummary$.next(this.summary);
          } else {
            this.data.packageSummary$.next(undefined);
          }
        });
    }
  }
  openDialog() {
    this.dialog.open(PackageDialogComponent, {
      width: '50%'
    });
  }
  ngOnDestroy() {}
}
