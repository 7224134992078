import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { DataService } from "../services/data/data.service";
import { Subscription } from "rxjs";
import { ApiService } from "../services/api/api.service";

@Component({
  selector: "app-package-invoice",
  templateUrl: "./package-invoice.component.html",
  styleUrls: ["./package-invoice.component.scss"]
})
export class PackageInvoiceComponent implements OnInit, OnDestroy {

  @Input() globalForm;

  public subscription: Subscription;
  public shipmentForm: FormGroup;
  response;
  spinner = false;
  zipMask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/];

  constructor(private data: DataService, private api: ApiService) {
    this.subscription = new Subscription();
  }

  public ngOnInit() {
    this.globalForm.get('payer.invoice').valueChanges.subscribe(checked => {
      if (checked) {
        const validators = Validators.compose([
          Validators.required
        ]);         
        this.globalForm.get('payer').addControl('name', new FormControl( '', [validators]));
        this.globalForm.get('payer.address').addControl('street', new FormControl( '', [validators, Validators.pattern("[\.\/a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][\.\/a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]));
        this.globalForm.get('payer.address').addControl('houseNo', new FormControl( '', [validators, Validators.pattern("[a-zA-Z0-9\/\-]+")]));
        this.globalForm.get('payer.address').addControl('flatNo', new FormControl( '', [Validators.pattern("[\.\/0-9a-zA-Z ]+")] ));
        this.globalForm.get('payer.address').addControl('zipCode', new FormControl( '', [validators, Validators.minLength(6)]));
        this.globalForm.get('payer.address').addControl('city', new FormControl( '', [validators, Validators.pattern("[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]));
        
      } else {       
        this.globalForm.get('payer').removeControl('name');
        this.globalForm.get('payer.address').removeControl('street');
        this.globalForm.get('payer.address').removeControl('houseNo');
        this.globalForm.get('payer.address').removeControl('zipCode');
        this.globalForm.get('payer.address').removeControl('city');
        this.globalForm.get('payer.address').removeControl('flatNo');

      }
      this.globalForm.updateValueAndValidity();
    });
    this.globalForm.get('payer.privatePerson').valueChanges.subscribe(checked => {
      if (!checked) {
        const validators = Validators.compose([ Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10) ]);
        this.globalForm.get('payer').addControl('taxId', new FormControl( '', validators));
      } else {
        this.globalForm.get('payer').removeControl('taxId');
      }
      this.globalForm.updateValueAndValidity();
    })
  }

  getCidg(taxId) {
    if(this.globalForm.get('payer.taxId').valid) {
      this.spinner = true;
      this.api.getCidg(taxId).subscribe(res => {       
        this.response = res;
        if(!this.response.IsSuccess) {
          alert('Błędny numer nip');
        } else {
          this.globalForm.get('payer').patchValue({
            name: this.response.Payer.Name
          });
    
          this.globalForm.get('payer.address').patchValue({
            street: this.response.Payer.Address.Street,
            houseNo: this.response.Payer.Address.HouseNo,
            flatNo: this.response.Payer.Address.FlatNo,
            zipCode: this.response.Payer.Address.ZipCode,
            city: this.response.Payer.Address.City
          });
        }
      });
      this.spinner = false;
    }
  }

  resetForm(param: boolean) {
    if (param) {
      this.globalForm.get('payer').reset()
      this.globalForm.get('payer').patchValue({invoice: true, privatePerson: false})
    } else {
      this.globalForm.get('payer').patchValue({invoice: false, privatePerson: true})
    }
  }

  public ngOnDestroy(): void {
   
  }
}
