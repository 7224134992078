import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data/data.service';

@Component({
  selector: 'app-package-payment',
  templateUrl: './package-payment.component.html',
  styleUrls: ['./package-payment.component.scss']
})
export class PackagePaymentComponent implements OnInit, OnDestroy {
  @Input() globalForm;

  public subscription: Subscription;
  public shipmentForm: FormGroup;

  constructor(private data: DataService) {
    this.subscription = new Subscription();
  }

  public ngOnInit(): void {
    this.subscription.add(this.data.shipmentForm$.subscribe(shipmentForm => this.shipmentForm = shipmentForm));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
