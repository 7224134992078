import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { of } from "rxjs/internal/observable/of";
import { Parcel } from "src/app/models/parcel.model";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { forkJoin } from "rxjs";
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class ApiService {
  private httpHeaders: HttpHeaders;
  loginek = {
    Login: "string",
    Password: "string"
  };
  amount = {amount: 7000};
  private _jsonURL = 'assets/kody.json';

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // tslint:disable-next-line: max-line-length
      Authorization:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFtYnJvX3BhcmNlbF9wcm94eSIsIm5iZiI6MTYwODAyOTA4MCwiZXhwIjoxNzY1Nzk1NDgwLCJpYXQiOjE2MDgwMjkwODAsImlzcyI6Imh0dHBzOi8vYW1icm8td3Rwcm94eS5vcGVubmV0LnBsL2FwaS9QYXltZW50VmVyaWZpY2F0aW9uL1ZlcmlmeSIsImF1ZCI6Imh0dHBzOi8vYW1icm8td3Rwcm94eS5vcGVubmV0LnBsL2FwaS9QYXltZW50VmVyaWZpY2F0aW9uL1ZlcmlmeSJ9.CZAyVRvjIvB3B4szGpeyt-T4nucNwZqOeDS_DyPiM2k"
    });
  }

  public getCidg(taxId) {
    return this.http.get(`${environment.apiUrl}/Cidg/?taxId=${taxId}`, {
      headers: this.httpHeaders
    });
  }
  public login() {
    return this.http.post(`${environment.apiUrl}/login`, this.loginek);
  }

  public getPackagePrice(form): Observable<any[]> {
    const response1 = this.http.post(`${environment.apiUrl}/Parcel`, form, {
      headers: this.httpHeaders
    });
    const response2 = this.http.post(
      `${environment.apiUrl}/Valuation/CalculateBasePrice`,
      form,
      { headers: this.httpHeaders }
    );

    return forkJoin([response1, response2]);
  }
  public getInsurance(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/Valuation/CalculateInsurancePrice`, this.amount, {
      headers: this.httpHeaders
    });
  }

  public getTracking(letterNo): Observable<any> {
    return this.http.get(`${environment.apiUrl}/Tracking/?letterNo=${letterNo}`, {
      headers: this.httpHeaders
    });
  }

  public putForm(form): Observable<any> {
    return this.http.put(`${environment.apiUrl}/Shipment`, form, {headers: this.httpHeaders});
  }

  public getPoints() {
    return this.http.get(`${environment.apiUrl}/Point/GetAll`, {headers: this.httpHeaders});
  }
  public getStatus(guid) {
    return this.http.get(`${environment.apiUrl}/Payment/GetStatus?Guid=${guid}`, {headers: this.httpHeaders})    
  }
  public getShipment(guid) {
    return this.http.get(`${environment.apiUrl}/Shipment?Guid=${guid}`, {headers: this.httpHeaders});
  }
  public getDocument(guid, documentType, format) {
    return this.http.get(`${environment.apiUrl}/Document?Guid=${guid}&documentType=${documentType}&format=${format}`, {headers: this.httpHeaders});
  }
  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }
}
