import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FormGroup } from '@angular/forms';
import { Form } from 'src/app/models/form.class';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public packageSummary$ = new BehaviorSubject(undefined);
  public packageInsurance$ = new BehaviorSubject(undefined);
  public payerForm$ = new BehaviorSubject(undefined);  

  public shipmentForm$ = new BehaviorSubject<FormGroup>(new Form().shipment);

  constructor() {
    
  }
}
