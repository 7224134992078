import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DataService } from '../services/data/data.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  title = 'PakaPunkt';
  public subscription: Subscription;
  public shipmentForm: FormGroup;
  mobile = false;
  validators = Validators.compose([
    Validators.required
    // Validators.minLength(26),
    // Validators.pattern("^[0-9]*$")
  ]);

  constructor(private data: DataService) {
    this.subscription = new Subscription();
  }

  parcel = new FormGroup({
    weight: new FormControl('', [Validators.required, Validators.pattern('^[0-9].*$'), Validators.max(50), Validators.min(0)]),
    dimensions: new FormGroup({
      lenght: new FormControl('', [Validators.required, Validators.pattern('^[0-9].*$'), Validators.max(200), Validators.min(1)]),
      height: new FormControl('', [Validators.required, Validators.pattern('^[0-9].*$'), Validators.max(200), Validators.min(1)]), 
      width: new FormControl('', [Validators.required, Validators.pattern('^[0-9].*$'), Validators.max(200), Validators.min(1)])
    })
  });

  cod = new FormGroup({
    checkbox: new FormControl(false)
  });

  insurance = new FormGroup({
    checkbox: new FormControl(false),
    amount: new FormControl('')
  });

  payer = new FormGroup({
    invoice: new FormControl(false),
    privatePerson: new FormControl(true),
    address: new FormGroup({
    })
  });
  sender = new FormGroup({
    senderDeliveryChecbox: new FormControl(true),
    name: new FormControl( '', [this.validators, Validators.minLength(3)]),
    contact: new FormGroup({
      emailAddress: new FormControl( '', [this.validators, Validators.email]),
      phoneNumber: new FormControl( '', this.validators)

    }),
    address: new FormGroup({
      street: new FormControl( '', [this.validators, Validators.pattern("[\.\/0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]),
      houseNo: new FormControl( '', [this.validators, Validators.pattern("[a-zA-Z0-9\/\-]+")]),
      zipCode: new FormControl( '', [this.validators, Validators.minLength(6)]),
      city: new FormControl( '', [this.validators, Validators.pattern("[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]),
      flatNo: new FormControl( '', [Validators.pattern("[\.\/0-9a-zA-Z ]+")])
    }),
    point: new FormGroup({
    })
  });
  recipient = new FormGroup({
    recipientDeliveryChecbox: new FormControl(true),
    name: new FormControl( '', [this.validators, Validators.minLength(3)]),
    contact: new FormGroup({
      emailAddress: new FormControl( '', [this.validators, Validators.email]),
      phoneNumber: new FormControl( '', this.validators)
    }),
    address: new FormGroup({
      street: new FormControl( '', [this.validators, Validators.pattern("[\.\/0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]),
      houseNo: new FormControl( '', [this.validators, Validators.pattern("[a-zA-Z0-9\/\-]+")]),
      zipCode: new FormControl( '', [this.validators, Validators.minLength(6)]),
      city: new FormControl( '', [this.validators, Validators.pattern("[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]),
      flatNo: new FormControl( '', [Validators.pattern("[\.\/0-9a-zA-Z ]+")])
    }),
    point: new FormGroup({
    })
  });

  payment = new FormGroup({
    payNow: new FormControl(true),
    print: new FormControl(true),
    qr: new FormControl(false)
  });

  paymentType = new FormControl('Prepaid');

  printType = new FormControl('Independent');
  
  qrCodeInfo = new FormControl('SendWithoutQrCode');

  globalForm = new FormGroup({
    parcel: this.parcel,
    cod: this.cod,
    insurance: this.insurance,
    payer: this.payer,
    sender: this.sender,
    recipient: this.recipient,
    paymentType: this.paymentType,
    printType: this.printType,
    qrCodeInfo: this.qrCodeInfo
  }
  );

  public ngOnInit() {
    this.subscription.add(this.data.shipmentForm$.subscribe(shipmentForm => this.shipmentForm = shipmentForm));
  
  }


  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
