import { Component, OnInit } from '@angular/core';

export interface PeriodicElement {
  name: string;  
  weight: number;
  length: number;
  height: number;
  width: number;
  pkg: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { name: 'Drzwi osobowe', pkg: 'Paczka', weight: 28, length: 135, width: 35, height: 120},
  { name: 'Maska mała', pkg: 'Paczka', weight: 20, length: 130, width: 30, height: 110},
  { name: 'Maska duża', pkg: 'Paczka', weight: 25, length: 150, width: 30, height: 140},
  { name: 'Zderzak do 200cm długości', pkg: 'Paczka', weight: 10, length: 180, width: 60, height: 60},
  { name: 'Zderzak 200-250cm długości', pkg: 'Paczka', weight: 15, length: 220, width: 60, height: 60},
  { name: 'Błotnik przód (komplet)', pkg: 'Gabaryt20', weight: 10, length: 100, width: 80, height: 40},
  { name: 'Klapa tył z szybą (osobowe)', pkg: 'Paleta', weight: 100, length: 120, width: 80, height: 170},
  { name: 'Klapa tył bez szyby (osobowe)', pkg: 'Paczka', weight: 50, length: 120, width: 80, height: 100},
  { name: 'Kompletny przód (pas+błotniki+maska+zderzak+lampy)', pkg: 'Paleta', weight: 100, length: 120, width: 80, height: 170},
  { name: 'Ćwiartka 120x100', pkg: 'Paleta', weight: 100, length: 120, width: 100, height: 170},
  { name: 'Ćwiartka 200x100', pkg: 'Paleta', weight: 100, length: 200, width: 100, height: 170},
  { name: 'Skrzynia biegów do 100kg', pkg: 'Paleta', weight: 100, length: 120, width: 80, height: 80},
  { name: 'Silnik do 200kg', pkg: 'Paleta', weight: 200, length: 120, width: 80, height: 80},
  { name: 'Fotel 1 osobowy do 50kg', pkg: 'Paczka', weight: 50, length: 110, width: 90, height: 100},
  { name: 'Szyba czołowa osobowy', pkg: 'Paczka', weight: 20, length: 160, width: 20, height: 120}  
];

@Component({
  selector: 'app-package-dialog',
  templateUrl: './package-dialog.component.html',
  styleUrls: ['./package-dialog.component.scss']
})
export class PackageDialogComponent implements OnInit {

  displayedColumns: string[] = ['name', 'pkg','weight','lenght','width', 'height'];
  dataSource = ELEMENT_DATA;

  constructor() { }

  ngOnInit() {
  }

}
