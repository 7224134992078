import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit
} from "@angular/core";
import { DataService } from "../services/data/data.service";

@Component({
  selector: "app-package-summary",
  templateUrl: "./package-summary.component.html",
  styleUrls: ["./package-summary.component.scss"]
})
export class PackageSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('summaryBox', { static: true }) summaryBox: ElementRef;

  packageSummary: any = undefined;
  packageInsurance: any = undefined;
  summaryPosition: number;
  sticky = false;

  @ViewChild('summary', { static: false }) summary: ElementRef;

  constructor(private data: DataService) {}

  ngOnInit() {
    this.data.packageSummary$.subscribe(res => (this.packageSummary = res));
    this.data.packageInsurance$.subscribe(res => (this.packageInsurance = res));
    
  }

  getSummaryPrice() { 
    if (this.packageSummary && this.packageInsurance) {
      return this.packageSummary[1].Price.Amount + this.packageInsurance;
    } else if (this.packageSummary) {
      return this.packageSummary[1].Price.Amount;
    } else if (this.packageInsurance) {
      return this.packageInsurance;
    } else {
      return 0;
    }
  }

  ngAfterViewInit() {
    this.summaryPosition = this.summary.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event']) handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.summaryPosition - 100) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }
}
