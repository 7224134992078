import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { SummaryQrComponent } from "./summary-qr/summary-qr.component";
import { SummaryPrintComponent } from "./summary-print/summary-print.component";
import { SearchPackageComponent } from "./search-package/search-package.component";
import { TrackingComponent } from "./tracking/tracking.component";
import { PendingComponent } from './pending/pending.component';
import { UnsuccesComponent } from './unsucces/unsucces.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "confirmation-qr", component: SummaryQrComponent },
  { path: "confirmation-print", component: SummaryPrintComponent },
  { path: "search", component: SearchPackageComponent },
  { path: "tracking/:letterNo", component: TrackingComponent },
  { path: "tracking", component: TrackingComponent },
  { path: "pending", component: PendingComponent }, 
  { path: 'unsucces', component: UnsuccesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
