import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-package-info',
  templateUrl: './package-info.component.html',
  styleUrls: ['./package-info.component.scss']
})
export class PackageInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
