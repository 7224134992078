import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, Validators, FormControl, EmailValidator } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService } from '../services/data/data.service';

@Component({
  selector: 'app-package-delivery',
  templateUrl: './package-delivery.component.html',
  styleUrls: ['./package-delivery.component.scss']
})
export class PackageDeliveryComponent implements  OnInit, OnDestroy {
  @Input() globalForm;

  public subscription: Subscription;
  public shipmentForm: FormGroup;

  constructor(private data: DataService) {
    this.subscription = new Subscription();
  }

  public ngOnInit() {
    this.globalForm.get('sender.senderDeliveryChecbox').valueChanges.subscribe(checked => {
      const validators = Validators.compose([
        Validators.required
        // Validators.minLength(26),
        // Validators.pattern("^[0-9]*$")
      ]);

      if (checked) {
        this.globalForm.get('sender').addControl('name', new FormControl( '', [validators, Validators.pattern("[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+"), Validators.minLength(3)]));
        this.globalForm.get('sender.address').addControl('street', new FormControl( '', [validators, Validators.pattern("[\.\/0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]));
        this.globalForm.get('sender.address').addControl('houseNo', new FormControl( '', [validators, Validators.pattern("[a-zA-Z0-9\/\-]+")]));
        this.globalForm.get('sender.address').addControl('zipCode', new FormControl( '', [validators, Validators.minLength(6)]));
        this.globalForm.get('sender.address').addControl('city', new FormControl( '', [validators, Validators.pattern("[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]));
        this.globalForm.get('sender.address').addControl('flatNo', new FormControl( '', [Validators.pattern("[\.\/0-9a-zA-Z ]+")]));
        // this.globalForm.get('sender.contact').addControl('emailAddress', new FormControl( '', [validators, Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]));
        this.globalForm.get('sender.contact').addControl('emailAddress', new FormControl( '', [validators, Validators.email]));
        this.globalForm.get('sender.contact').addControl('phoneNumber', new FormControl( '', validators));
        this.globalForm.get('sender.point').removeControl('no');
        this.globalForm.get('sender.point').removeControl('visibleNo');
 
      } else {
        this.globalForm.get('sender').addControl('name', new FormControl( '', [validators, Validators.pattern("[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+"), Validators.minLength(3)]));
        this.globalForm.get('sender.address').removeControl('street');
        this.globalForm.get('sender.address').removeControl('houseNo');
        this.globalForm.get('sender.address').removeControl('zipCode');
        this.globalForm.get('sender.address').removeControl('city');
        this.globalForm.get('sender.address').removeControl('flatNo');
        // this.globalForm.get('sender.contact').addControl('emailAddress', new FormControl( '', [validators, Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]));
        this.globalForm.get('sender.contact').addControl('emailAddress', new FormControl( '', [validators, Validators.email]));
        this.globalForm.get('sender.contact').addControl('phoneNumber', new FormControl( '', validators));
        this.globalForm.get('sender.point').addControl('no', new FormControl( '', validators));
        this.globalForm.get('sender.point').addControl('visibleNo', new FormControl( '', validators));
      }
    })

    this.globalForm.get('recipient.recipientDeliveryChecbox').valueChanges.subscribe(checked => {
      const validators = Validators.compose([
        Validators.required
        // Validators.minLength(26),
        // Validators.pattern("^[0-9]*$")
      ]);

      if (checked) {
        this.globalForm.get('recipient').addControl('name', new FormControl( '', [validators, Validators.pattern("[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+"), Validators.minLength(3)]));
        this.globalForm.get('recipient.address').addControl('street', new FormControl( '', [validators, Validators.pattern("[\.\/0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]));
        this.globalForm.get('recipient.address').addControl('houseNo', new FormControl( '', [validators, Validators.pattern("[a-zA-Z0-9\/\-]+")]));
        this.globalForm.get('recipient.address').addControl('zipCode', new FormControl( '', [validators, Validators.minLength(6)]));
        this.globalForm.get('recipient.address').addControl('city', new FormControl( '', [validators, Validators.pattern("[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+")]));
        this.globalForm.get('recipient.address').addControl('flatNo', new FormControl( '', [Validators.pattern("[\.\/0-9a-zA-Z ]+")]));
        // this.globalForm.get('recipient.contact').addControl('emailAddress', new FormControl( '', [validators, Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]));
        this.globalForm.get('recipient.contact').addControl('emailAddress', new FormControl( '', [validators, Validators.email]));
        this.globalForm.get('recipient.contact').addControl('phoneNumber', new FormControl( '', validators));
        this.globalForm.get('recipient.point').removeControl('no');
        this.globalForm.get('recipient.point').removeControl('visibleNo');

      } else {
        this.globalForm.get('recipient').addControl('name', new FormControl( '', [validators, Validators.pattern("[a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ ]+"), Validators.minLength(3)]));
        this.globalForm.get('recipient.address').removeControl('street');
        this.globalForm.get('recipient.address').removeControl('houseNo');
        this.globalForm.get('recipient.address').removeControl('zipCode');
        this.globalForm.get('recipient.address').removeControl('city');
        this.globalForm.get('recipient.address').removeControl('flatNo');
        // this.globalForm.get('recipient.contact').addControl('emailAddress', new FormControl( '', [validators, Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")]));
        this.globalForm.get('recipient.contact').addControl('emailAddress', new FormControl( '', [validators, Validators.email]));
        this.globalForm.get('recipient.contact').addControl('phoneNumber', new FormControl( '', validators));
        this.globalForm.get('recipient.point').addControl('no', new FormControl( '', validators));
        this.globalForm.get('recipient.point').addControl('visibleNo', new FormControl( '', validators));
      }
    })

    this.subscription.add(this.data.shipmentForm$.subscribe(shipmentForm => this.shipmentForm = shipmentForm));
  }
  test() {
    console.log(this.globalForm.get('sender'));
  }
  resetPayForm() {
    this.globalForm.get('paymentType').setValue('Prepaid');
    this.globalForm.get('printType').setValue('Independent');
    this.globalForm.get('qrCodeInfo').setValue('SendWithoutQrCode');
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
