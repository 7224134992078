import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
declare const SMWidget: any;

@Component({
  selector: 'app-rec-map',
  templateUrl: './rec-map.component.html',
  styleUrls: ['./rec-map.component.scss']
})
export class RecMapComponent implements OnInit, OnDestroy {

  pointer;
  test;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RecMapComponent>) {}

  ngOnInit() {
    SMWidget.pointer = undefined;    
    SMWidget.init('mapid', 'http://projectmanager24.pl/punkty/getpts.php', {
      mapType: 'OSM',
      selectCb: this.onSelect.bind(this),

    });    
  }

  onSelect(p) {
    this.pointer = p; 
    this.dialogRef.close(this.pointer);
    SMWidget.el = undefined;
  } 
 
  ngOnDestroy(): void {
    SMWidget.el = undefined;
  }

}
