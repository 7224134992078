import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ApiService } from '../services/api/api.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-save",
  templateUrl: "./save.component.html",
  styleUrls: ["./save.component.scss"]
})
export class SaveComponent implements OnInit {
  @Input() globalForm;
  output;
  spinner = false;

  constructor(private api: ApiService, private router: Router,) {}

  sendForm() {
    this.spinner = true;    
    if (this.globalForm.valid) {      
      this.output = JSON.stringify(this.globalForm.value);      
      this.api.putForm(this.output).subscribe(res => {       
        if(res.IsSuccess) {
          let redirectToPayment = res.Letter.RedirectUrl;
          window.location.href = redirectToPayment;
        } else {
          this.spinner = false;          
        }
      });
    } else {
      this.spinner = false;
      this.validateAllFormFields(this.globalForm);
    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  public ngOnInit() {}
}
