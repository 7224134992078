import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-summary-qr',
  templateUrl: './summary-qr.component.html',
  styleUrls: ['./summary-qr.component.scss']
})
export class SummaryQrComponent implements OnInit {
  private guid;
  private status;
  shipment;
  document;
  currPhoto;
  base64Image = '';
  tranformedImage = '';
  loadedPhoto = false;

  constructor(private router: Router, private route: ActivatedRoute, private api: ApiService, private sanitizer: DomSanitizer) { }


  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.guid = params.get("t");
      this.api.getStatus(this.guid).subscribe(res => {
       
          this.api.getShipment(this.guid).subscribe(res => {
            this.shipment = res;
          });
          this.api.getDocument(this.guid, 'QrCode', 'JPG').subscribe(res => {
            this.document = res;            
            this.currPhoto = this.document.Document.Content;
            this.loadedPhoto = true;
            this.base64Image = `data:image/jpeg;base64,${this.currPhoto}`;
          })   
      }, err => {        
        if(err.status == '500') {
          this.router.navigate(['/unsucces']);
        }
      }
      )
    })
  }
}
