import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-summary-print',
  templateUrl: './summary-print.component.html',
  styleUrls: ['./summary-print.component.scss']
})
export class SummaryPrintComponent implements OnInit {
  guid;
  shipment;
  document;
  currPdf;
  savePdf;

  constructor(private router: Router, private route: ActivatedRoute, private api: ApiService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.guid = params.get("t");
      this.api.getStatus(this.guid).subscribe(res => {

        this.api.getShipment(this.guid).subscribe(res => {
          this.shipment = res; 
        });
        this.api.getDocument(this.guid, 'Confirmation', 'PDF').subscribe(res => {
          this.document = res;
          this.currPdf = this.document.Document.Content;           
          this.savePdf = this.sanitizer.bypassSecurityTrustHtml(this.currPdf);
        }
        )
      }, (err: Response) => {
        if (err.status === 500) {
          this.router.navigate(['/unsucces']);
        } else {
          alert('An unexpected error occurred.')          
        }
      })
    })
  }
  showPdf() {
    const linkSource = 'data:application/pdf;base64,' + this.currPdf;
    const downloadLink = document.createElement("a");
    const fileName = "confirmation.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
}
