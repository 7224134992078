import { Component, OnInit } from "@angular/core";
import { ApiService } from "../services/api/api.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-tracking",
  templateUrl: "./tracking.component.html",
  styleUrls: ["./tracking.component.scss"]
})
export class TrackingComponent implements OnInit {
  package;
  lastPackage;
  letterInput = "";
  trackSucces = false;
  falseTrack = false;

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let letterNo = params.get("letterNo");
      this.lastPackage = letterNo;

      this.api.getTracking(letterNo).subscribe(res => {
        this.package = res;
        this.letterInput = "";
        if (this.package.IsSuccess) {
          this.trackSucces = true;
          this.falseTrack = false;
        } else {
          this.package.IsSuccess = false;
          this.falseTrack = true;
        }
      });
    });
  }
  getTracking(letterNo) {
    this.lastPackage = letterNo.value;

    this.api.getTracking(letterNo.value).subscribe(res => {
      this.package = res;
      console.log(this.package)
      this.letterInput = "";
      if (this.package.IsSuccess) {
        this.trackSucces = true;
        this.falseTrack = false;
      } else {
        this.package.IsSuccess = false;
        this.falseTrack = true;
      }
    });
  }
  
}
